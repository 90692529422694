import {
    AfterViewInit,
    Directive,
    HostListener,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Directive()
export abstract class IonSlidesBase implements AfterViewInit, OnDestroy {
    @ViewChild(IonSlides) private slides: IonSlides;

    activeIndex: number;

    protected constructor(public slideOptions, private switchBackHistory) {}

    @HostListener('window:popstate', ['$event'])
    async popstateHandler() {
        if (this.switchBackHistory) {
            await this.prev();
        }
    }

    async initActiveIndex() {
        this.activeIndex = await this.slides.getActiveIndex();
    }

    #updateHistoryState() {
        history.pushState({ ...history.state, slide: this.activeIndex }, null);
    }

    async ngAfterViewInit() {
        await this.initActiveIndex();

        if (this.switchBackHistory) {
            const modalState = {
                modal: true,
                slide: this.activeIndex,
            };

            history.pushState(modalState, null);
        }
    }

    async next() {
        await this.slides.slideNext();
        await this.initActiveIndex();

        if (this.switchBackHistory) {
            this.#updateHistoryState();
        }
    }

    async prev() {
        await this.slides.slidePrev();
        await this.initActiveIndex();

        if (this.switchBackHistory) {
            this.#updateHistoryState();
        }
    }

    ngOnDestroy() {
        if (history.state.modal) {
            history.back();
        }
    }
}
