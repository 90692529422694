import { Injectable } from '@angular/core';
import { ComponentCanDeactivate } from 'src/app/interfaces/can-deactivate.interface';
import { AuthRoutes, PinCodeRoutes } from 'src/app/enums/routes';
import { AuthService } from 'src/app/services/auth.service';
import {
    InputRequiredEnum,
    PinCodeService,
} from 'src/app/services/pin-code.service';

@Injectable({
    providedIn: 'root',
})
export class ExitRootComponentGuard implements ComponentCanDeactivate {
    constructor(
        private readonly authService: AuthService,
        private readonly pinCodeService: PinCodeService
    ) {}

    canDeactivate(component: ComponentCanDeactivate, nextState) {
        if (this.authService.loggedIn) {
            if (nextState.url.includes(AuthRoutes.AUTH)) {
                return false;
            }

            if (
                nextState.url.includes(PinCodeRoutes.PIN_CODE) &&
                this.pinCodeService.isInputRequired.value ===
                    InputRequiredEnum.NO_NEED
            ) {
                return false;
            }
        }

        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
